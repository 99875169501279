.intake-background {
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.intake-background .row {
    background: rgb(242, 220, 206);
    background: linear-gradient(45deg, rgba(242, 220, 206, 1) 0%, rgba(252, 232, 241, 1) 45%, rgba(171, 171, 219, 1) 100%);
}

.intake-background-blurred {
    filter: blur(10px);
    opacity: 0.5;
}

.bg-left-panel {
    background-image: url('/public/intake/Laptop_CrewMembers.webp');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
}

.bg-right-panel {
    background-image: url('/public/intake/ScreensCrewmember.webp');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
}

@media screen and (max-width: 768px) {
    .bg-left-panel {
        height: 50vh;
    }

    .bg-right-panel {
        height: 50vh;
    }
}