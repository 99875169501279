.common-button {
    width: auto;
    padding: 20px 40px 20px 40px;

    align-items: center; /* Vertically centers the content */
    justify-content: center; /* Horizontally centers the content */

    font-weight: 700;
    color: white;

    display: flex;
    background-color: #776e9d;
    box-shadow: #846daa85 0px 4px 4px 0px;

    border-color: white;
    border-radius: 100px;
    border-style: solid;
    border-width: 1px;

    transition: 
        background-color 0.5s ease-out, 
        color 0.5s ease-out, 
        box-shadow 0.5s ease-out,
        transform 0.5s ease-out, 
        width 1s ease-out, 
        height 1s ease-out;
}

.common-button:hover {
    background-color: #776e9dc4;
    border-color: white;
}

.common-button:disabled {
    background-color: #776e9d34;
    border-color: white;
    color: #ffffff8d;
}

.common-button:active {
    background-color: #776e9d;
    border-color: white;
}

.common-button.attract {
    animation: button-pulsate 2s infinite;
}

@keyframes button-pulsate {
    0% {
        box-shadow: 0 0 5px rgba(71, 27, 114, 0.5), 0 0 10px rgba(71, 27, 114, 0.4), 0 0 40px rgba(71, 27, 114, 0.3);
    }

    50% {
        box-shadow: 0 0 10px rgba(71, 27, 114, 0.7), 0 0 20px rgba(71, 27, 114, 0.6), 0 0 60px rgba(71, 27, 114, 0.5);
    }

    100% {
        box-shadow: 0 0 5px rgba(71, 27, 114, 0.5), 0 0 10px rgba(71, 27, 114, 0.4), 0 0 40px rgba(71, 27, 114, 0.3);
    }
}