.onboarding-step {
    width: 20px;
    height: 20px;
    top: 6px;
}

.onboarding-step button {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border-width: 2px;
    border-color: #776e9d;
    border-style: solid;
    box-shadow: none;
}

.onboarding-step button:hover {
    border-color: #776e9d;
    background-color: #776e9d;
}

.onboarding-step-selected {
    background-color: #776e9d;
}

.onboarding-step-unselected {
    background-color: transparent;
}