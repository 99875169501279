.achievement-item-container {
    width: 240px;
    height: 240px;
    position: relative;
    padding: 0;
}

.achievement-item-container .lock-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.achievement-item-container .character-image {
    position: absolute;
    width: 200px;
    height: auto;
}

.achievement-item-container .character-image-locked {
    filter: brightness(0) invert(1) contrast(1000%) opacity(1) invert(0.2);
}