.celebration-popup {
    background-color: rgba(237, 240, 255, 0.78);
    backdrop-filter: blur(10px);

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.celebration-popup.hide {
    display: none;
}

.celebration-popup.show {
    display: block;
}

.celebration-popup-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;
    margin: 0 auto;

    max-width: 1300px;
}

.celebration-popup-panel {
    min-height: 50vh;
    overflow: hidden;
}

.celebration-popup-buttons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.celebration-character-container {
    width: 400px;
    height: 400px;
    position: relative;

    pointer-events: none;
    user-select: none;
}

.celebration-character-image {
    width: 380px;
    height: auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.celebration-character-achievement {
    width: 140px;
    height: auto;

    position: absolute;
    top: 0;
}

.celebration-character-beams {
    width: 600px;
    height: auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    animation: spin-beams 15s linear infinite;
}

@keyframes spin-beams {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}


.celebration-header {
    font-size: 1.8em;
    font-weight: 700;

    margin-top: 40px;
    margin-bottom: 60px;
}

.celebration-body {
    font-size: 1.1em;
}