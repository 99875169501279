.coach-image {
    width: 300px;
    height: auto;
    animation: floating-animation 5s infinite alternate ease-in-out;
    transition: transform 0.5s, box-shadow 0.5s ease-in-out;
    filter: drop-shadow(0 4px 18px #846daa96);

    user-select: none;
}

@keyframes floating-animation {
    0% {
        transform: translate(0, 0px);
        filter: drop-shadow(0 4px 18px #846daa96);
    }

    50% {
        transform: translate(0, -15px);
        filter: drop-shadow(0 24px 18px #846daa96);
    }

    100% {
        transform: translate(0, -0px);
        filter: drop-shadow(0 4px 18px #846daa96);
    }
}