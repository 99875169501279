.btn-coach {
    background-color: #444791;
    color: white;
    border: none;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 8px;

    box-shadow: 2px 4px 4px rgba(134, 144, 163, .5);

    display: flex;
    align-items: center;
}

.btn-coach:hover {
    background-color: #6268b2;
    color: white;
}

.btn-coach:active,
.btn-coach:visited {
    background-color: #2f3470 !important;
}

.btn-coach:disabled {
    background-color: #bec0ed;
}

.btn-coach img {
    height: 2em;
    margin-left: 8px;
}

.btn-coach:disabled img {
    animation: pulsate 1s infinite;
}

@keyframes pulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}