body {
    background: rgb(227, 233, 245);
    background: linear-gradient(256deg, rgba(227, 233, 245, 1) 0%, rgba(234, 230, 246, 1) 18%, rgba(239, 247, 252, 1) 34%, rgba(241, 232, 232, 1) 65%, rgba(232, 226, 248, 1) 100%);
}

.header-container {
    margin-top: 40px;
    text-align: left;
}

.header-icon {
    width: 105px;
}

.header-text-col {
    max-width: 850px;
}

.header-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #314184;
}

.header-subtitle {
    font-size: 1rem;
    color: #314184;
}

.category-header-title {
    font-size: 1.1rem;
    font-weight: 700;
    color: #AD9DDE;
    margin-bottom: 20px;
}

.category-progress {
    font-size: 1.5rem;
    font-weight: 700;
    color: #AD9DDE;
}

.category-icon {
    width: 96px;
    height: auto;
}

.category-divider {
    color: #8281b1;
    opacity: 0.5;
    border-width: 1px;
}

.rank-label {
    font-size: 1.6rem;
    font-weight: 700;
    color: #314184
}

.rank-current-value {
    font-size: 2rem;
    font-weight: 700;
    color: #314184;
    margin-right: 0.2rem;
}

.rank-total-value {
    font-size: 1rem;
    font-weight: 600;
    color: #314184
}

.rank-description {
    font-size: 0.8rem;
    font-weight: bold;
    color: #314184
}