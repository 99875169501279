* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #505398;

  background: rgb(244, 243, 255);
  background: linear-gradient(45deg, rgba(244, 243, 255, 1) 0%, rgba(228, 241, 255, 1) 33%, rgba(233, 237, 255, 1) 66%, rgba(230, 247, 255, 1) 100%);
}

.btn-primary {
  background-color: #444791;
  color: white;
  border: none;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 8px;
  line-height: 1.25rem;

  box-shadow: 2px 4px 4px rgba(134, 144, 163, .5);
}

.btn-primary:hover {
  background-color: #6268b2;
}

.btn-primary:active, .btn-primary:visited {
  background-color: #2f3470 !important;
}

.btn-primary:disabled {
  background-color: #bec0ed;
}

.btn-dark {
  background-color: white;
  color: #444791;
  border: none;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 8px;
  line-height: 1.25rem;

  box-shadow: 2px 4px 4px rgba(42, 48, 79, .5);
}

.btn-dark:hover {
  background-color: #6268b2;
}

.btn-dark:active, .btn-dark:visited {
  background-color: #2f3470 !important;
}

.btn-dark:disabled {
  background-color: #bec0ed;
}