.lesson-card-container {
    width: 430px;
    height: 264px;
    position: relative;
    padding: 0;
}

.lesson-card {
    height: 100%;
    width: 100%;
    border-radius: 20px;

    overflow: hidden;
    position: relative;
}

.lesson-card-locked {
    background: rgb(198, 211, 245);
    background: linear-gradient(216deg, rgba(198, 211, 245, 1) 0%, rgba(167, 177, 210, 1) 51%);
    box-shadow: 0px 4px 20px 6px #bfb0df9b, 0px 4px 0px 0px #6c4455;
}

.lesson-card-available {
    background: rgb(247, 136, 182);
    background: linear-gradient(198deg, rgba(247, 136, 182, 1) 32%, #c15d87 100%);
    box-shadow: 0px 4px 20px 6px #beafde9b, 0px 4px 0px 0px #422a51;
}

.lesson-card-finished {
    background: #E8DF93;
    background: linear-gradient(198deg, #E8DF93 10%, #E39954 100%);
    box-shadow: 0px 4px 20px 6px #bfb0df9b, 0px 4px 0px 0px #8B4C48;
}

.lesson-card img {
    pointer-events: none;
    user-select: none;
}

.lesson-card button {
    background-color: #ffffff2e;

    border-radius: 100px;
    border-width: 0px;

    width: auto;
    height: 46px;

    color: white;
    font-weight: 600;
    font-size: 20px;

    position: absolute;
    bottom: 30px;
    right: 30px;

    box-shadow: 0px 4px 4px 0px #471B7240;

    padding: 10px 30px 10px 30px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.lesson-card .lesson-button-available {
    background-color: #ffffff69;
}

.lesson-card .lesson-button-continue {
    background-color: #ffffff69;
    box-shadow: 0px 2px 8px 4px rgba(255, 255, 255, 0.7);
}

.lesson-card button:hover {
    background-color: #ffffff83;
}

.lesson-card button:active {
    background-color: #ffffffa2;
}

.lesson-card button:disabled {
    pointer-events: none;
}


.lesson-card span {
    color: white;
    font-weight: bold;
    font-size: 24px;
    text-align: right;

    text-shadow: 1px 1px 2px #461b72;

    position: absolute;
    top: 30px;
    right: 30px;
    width: 280px;
}

.lesson-card .card-logo {
    position: absolute;
    bottom: -1px;
    left: -1px;
    width: 200px;
    height: auto;
}

.lesson-card-locked .card-logo {
    filter: brightness(0) invert(1) contrast(1000%) opacity(0.5);
}

.lesson-card-locked span {
    color: #E9EAF9;
    text-shadow: 1px 1px 2px #5666A3;
}

.lesson-card-locked button {
    text-shadow: none;
    color: rgba(255, 255, 255, 0.5);
    box-shadow: none;
}


.lesson-completed-badge {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0;
    height: 0;
}

.lesson-completed-badge img {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 90px;
}

.lesson-bg-completed {
    position: absolute;
    top: -1;
    left: -1;
}

.lesson-bg-available {
    position: absolute;
    bottom: -1;
    left: -1;
}

.lesson-bg-locked {
    position: absolute;
    bottom: -1;
    left: -1;
    filter: opacity(0.5);
}