.coach-page-panel {
    margin-top: 80px;
    margin-bottom: 80px;
}

.coach-page-panel h1 {
    margin-bottom: 60px;
    font-size: 36px;
    font-weight: bold;
}

.coach-page-panel .coach-page-text {
    white-space: pre-line;
}