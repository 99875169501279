.notice-popup {
    background-color: rgba(237, 240, 255, 0.78);
    backdrop-filter: blur(10px);

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 1000;
}

.notice-popup.hide {
    display: none;
}

.notice-popup.show {
    display: block;
}

.notice-popup-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;
    margin: 0 auto;
}

.notice-popup-panel {}

.notice-popup-buttons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}