.lesson-title {
    font-size: 1rem;
    position: absolute;
    top: 20px;
    color: #8B8DCD;
}

.lesson-content-panel {
    margin-top: 20px;
    position: relative;
}

.lesson-content {
    margin-top: 20px;
}

.lesson-content img {
    user-select: none;
}

.lesson-content h1 {
    font-size: 1.8rem;
    margin-bottom: .8rem;
}

.lesson-content h2 {
    font-size: 1.4rem;
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}

.lesson-content h3 {
    font-size: 1.2rem;
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}

.lesson-content h4 {
    font-size: 1rem;
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}

.lesson-content ul {
    margin-top: -.5rem;
}

.lesson-content ol {
    margin-top: -.5rem;
}