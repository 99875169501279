.onboarding-container {
    margin-top: 40px;
}

.onboarding-content-panel {
    position: relative;
    background: rgb(255, 255, 255, 0.8);
    background: linear-gradient(118deg, rgba(255, 255, 255, 0.8) 0%, rgba(233, 246, 255, 0.8) 100%);
    min-height: 70vh;
}

.onboarding-content {
    margin-top: 20px;
}

.onboarding-content h1 {
    font-size: 1.8rem;
    margin-bottom: 1.4rem;
}

.onboarding-content h2 {
    font-size: 1.4rem;
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}

.onboarding-content h3 {
    font-size: 1.2rem;
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}

.onboarding-content h4 {
    font-size: 1rem;
    margin-top: 1.8rem;
    margin-bottom: .8rem;
}

.onboarding-content ul {
    margin-top: -.5rem;
}

.onboarding-content ol {
    margin-top: -.5rem;
}