/* Ensure the container takes up the full screen */
.vh-100 {
    height: 100vh;
}

.header-row {
    position: absolute;
    top: 40px;
    left: 40px;
}

.header-row h1 {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -1.2px;
    color: #2F2E63;
    margin: 0;
    padding: 0;
}

.welcome-container {
    position: absolute;
    top: 0;
    left: 0;
}

.welcome-row {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.welcome-panel {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 40vw;
}

.welcome-panel p {
    margin-bottom: 3rem;
}